import React from 'react';
import ReactDOM from 'react-dom/client';
import {Routes} from './Routes'
import AuthProvider from "./components/AuthProvider";
import "./css/main.css";
import './css/landing.style.css';
import { isGodMode } from './helpers';

window.isGodMode = () => {
  return isGodMode();
};

ReactDOM.createRoot(document.getElementById("root")).render(
    <AuthProvider>
      <Routes />
    </AuthProvider>
);
